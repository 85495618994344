import React, { useState } from 'react';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import emailjs from 'emailjs-com';

import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: ''})
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  }

  const handleSubmit = () => {
    setLoading(true);

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message
    };

    emailjs
    .send('service_iizwdzu', 'template_yzyb1st', templateParams, process.env.USER_ID)
    .then(() => {
      setLoading(false);
      setIsFormSubmitted(true);
    })
    .catch((error) => {
      console.error('Error sending email:', error);
      setLoading(false);
    });
  }
  return (
    <>
      <h2 className="head-text">Contact Me</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" id="app__footer-card-email" />
          <a href="mailto:helen.lehrer.dev+website@gmail.com" className="p-text">helen.lehrer.dev@gmail.com</a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="mobile"
          id="app__footer-card-mobile" />
          <a href="tel: +1 (561) 847-6819 " className="p-text">+1 (561) 847-6819</a>
        </div>
      </div>

    {!isFormSubmitted ? 
      <div className="app__footer-form app__flex">
        <div className="app__flex">
          <input
          className="p-text"
          type="text"
          placeholder="Your Name"
          name="name"
          value={name}
          onChange={handleChangeInput} />
        </div>
        <div className="app__flex">
          <input 
          className="p-text"
          type="email"
          placeholder="Your Email"
          name="email"
          value={email}
          onChange={handleChangeInput} />
        </div>
        <div>
          <textarea
          className="p-text"
          placeholder="Your Message"
          value={message}
          name="message"
          onChange={handleChangeInput}>
          </textarea>
      </div>
      <button type="button" className="p-text" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
    </div>
    : <div>
      <h3 className="head-text">Thank you for getting in touch!</h3>
    </div>}

    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg'
)