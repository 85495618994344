/* eslint-disable import/no-anonymous-default-export */
import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import api from '../assets/api.png';
import live from '../assets/live.png';
import css from '../assets/css.png';
import figma from '../assets/figma.png';
import git from '../assets/git.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import node from '../assets/node.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';
import sass from '../assets/sass.png';
import typescript from '../assets/typescript.png';
import csharp from '../assets/csharp.png';

import about01 from '../assets/about01.png';
import about02 from '../assets/about02.png';
import about03 from '../assets/about03.png';
import about04 from '../assets/about04.png';

import profile from '../assets/profile.png';
import prism from '../assets/prism.png';


export default {
  email,
  mobile,
  api,
  live,
  css,
  figma,
  git,
  html,
  javascript,
  node,
  react,
  redux,
  sass,
  typescript,
  about01,
  about02,
  about03,
  about04,
  profile,
  prism,
  csharp

};