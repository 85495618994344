import React, {useState, useEffect} from 'react';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import resume from './../../assets/pdf/Resume.pdf';

import './Skills.scss';

const Skills = () => {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const skillsQuery = '*[_type == "skills"]';

    client.fetch(skillsQuery).then((data) => {
      setSkills(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">Skills</h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills
            .sort((a, b) => {
              if (a.sort < b.sort) {
                return -1;
              } else if (a.sort > b.sort) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((skill) => (
              <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className="app__skills-item app__flex"
                key={skill.name}
              >
                <div
                  className="app__flex"
                  style={{ backgroundColor: skill.bgColor }}
                >
                  <img src={urlFor(skill.icon)} alt={skill.name} />
                </div>
                <p className="p-text">{skill.name}</p>
              </motion.div>
          ))}
        </motion.div>
      </div>
      <a href={resume} target="_blank" 
      rel="noreferrer"
      className="resume-button">
      Resume
       </a> 
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
   'skills',
   "app__whitebg"
   );